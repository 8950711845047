<template>
    <nav class="topbar">
        <div class="container">
            <router-link to="/">Home</router-link>
            <router-link to="/contact">Contact</router-link>
            <router-link to="/about">About</router-link>
            <button type="button" class="float-right mdi-lightbulb-on" title="Dark/light mode" @click="switchMode"></button>
            <a href='https://twitter.com/chriskulbacki' target='_blank' class="button float-right mdi-twitter" title="Twitter"></a>
            <a href='https://www.linkedin.com/in/chriskulbacki' target='_blank' class="button float-right mdi-linkedin" title="LinkedIn"></a>
        </div>
    </nav>
    <div id='header'>
        <div class="container">
            <h1><a href='/'>Chris Kulbacki</a></h1>
        </div>
    </div>
    <div id='page'>
        <div class="container">
            <router-view/>
        </div>
    </div>
    <div id="footer">
        <div class='container'>
            Copyright &copy 2007-{{ new Date().getFullYear() }} Chris Kulbacki.
        </div>
    </div>
</template>

<script>
    import { useCookies } from "vue3-cookies";

    export default {
        setup() {
            const { cookies } = useCookies();
            return { cookies };
        },

        // data() {
        //     return {
        //     }
        // },

        mounted() {
            if (this.cookies.get("xlight")) {
                document.documentElement.classList.add('xlight');
            }
        },

        methods: {
            switchMode() {
                if (document.documentElement.classList.contains("xlight")) {
                    document.documentElement.classList.remove('xlight');
                    this.cookies.remove("xlight");
                } else {
                    document.documentElement.classList.add('xlight');
                    this.cookies.set("xlight", "1", "1y");
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "ravencss/icons/css/materialdesignicons.css";
    @import "ravencss/raven.scss";

    body {
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 16px;
    }

    .container {
        max-width: 1100px;
        margin: auto;
        padding: 20px;
    }

    h3.page-title,
    h3.article-title {
        font-weight: normal;
        font-size: 1.8rem;
        padding: 0;
        margin: 2rem 0;
    }

    p {
        padding: 0;
        margin: 0 0 1rem 0;
    }

    p,
    .article-box .summary {
        line-height: 1.5rem;
    }

    li {
        padding-bottom: 8px;
    }

    nav.topbar {
        background: transparent !important;
        padding: 1rem 0;

        .container {
            padding: 0;

            a,
            button {
                font-weight: bold;
                padding: 0.5rem 0.9rem !important;

                &.router-link-active {
                    @include background-color-step(10);
                }

                &:before {
                    font-weight: normal;
                }
            }
        }
    }

    #header {
        background: url(assets/hero.png) no-repeat center center;
        background-size: cover;
        height: 140px;
        text-align: center;

        .container {
            padding: 0;
        }

        h1 {
            display: inline-block;
            position: relative;
            top: 27px;
            padding: 0;
            margin: 0 auto;
            background: rgba(0, 0, 0, 0.7);
            line-height: 1em;
            padding: 15px 20px 5px 20px;
            border: 3px solid #f5f5f5;
            font-size: 3.5em;
            font-weight: normal;
            font-family: 'Special Elite', sans-serif;

            a {
                color: #f5f5f5;
                text-decoration: none !important;
            }
        }
    }

    #footer {
        margin: 80px 0 40px 0;
        padding: 20px 0;
        border-top: 1px solid;
        text-align: center;
        font-size: 0.8em;
        @include border-color-step(20);
    }

    @media (max-width: 767px) {
        #header {
            height: 80px;

            h1 {
                top: 15px;
                margin-top: 0px;
                font-size: 2em;
            }

        }

        #menu ul{
            text-align: center;

            a {
                padding: 10px 5px;
            }
        }
    }
</style>