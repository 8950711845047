import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PageLoader from './components/PageLoader.vue'

const app = createApp(App)

app.component('PageLoader', PageLoader);

app.use(router)
app.config.globalProperties.ajaxUrl = process.env.NODE_ENV == "development" ? "http://xchriskulbacki.com/api/" : "/api/";
app.mount('#app')
