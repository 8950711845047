<template>
    <div id="page-home">
        <div class='article-list'>
            <PageLoader v-if="!articles.length"/>
            <div class="article-box" v-for="article in articles" :key="article.slug">
                <h3><router-link :to="'/articles/' + article.slug">{{ article.title }}</router-link></h3>
                <router-link :to="'/articles/' + article.slug" class="image">
                    <img class="img-responsive" :src="'/images/blog/' + article.slug + '/list.jpg'" alt="">
                </router-link>
                <div class="summary" v-html="article.summary"></div>
                <div style="clear:both"></div>
            </div>
        </div>
        <div class='sidebar'>
            <div class='sidebar-box about'>
                <h3>About Chris</h3>
                <p>
                    I'm an entrepreneur, motorcyclist, traveler, Agile trainer and software product expert with nearly
                    30 years of professional experience. I'm a founder of <a href="https://scrumstar.com">Scrum Star Academy</a>.
                </p>
            </div>
            <div class='sidebar-box twitter'>
                <a class="twitter-timeline" href="https://twitter.com/chriskulbacki" data-tweet-limit="6" data-theme="dark"></a>
                <a class="twitter-timeline" href="https://twitter.com/chriskulbacki" data-tweet-limit="6"></a>
            </div>
            <div class='sidebar-box popular'>
                <h3>Popular articles</h3>
                <ul class="popular-articles">
                    <li v-for="article in articles">
                        <router-link :to="'/articles/' + article.slug">{{ article.title }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                articles: [],
                popular: [],
            }
        },

        setup() {
        },

        mounted() {
            let twitterScript = document.createElement('script');
            twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js');
            document.head.appendChild(twitterScript);

            axios.get(this.ajaxUrl + '?route=summaries').then(response => {
                if (response.data.success) {
                    this.articles = response.data.data;
                }
            })

            axios.get(this.ajaxUrl + '?route=popular').then(response => {
                if (response.data.success) {
                    this.popular = response.data.data;
                }
            })
        },

        methods: {
        }
    }
</script>

<style lang="scss">
    @import "../ravencss/raven.scss";

    #page-home {
        display: flex;

        .article-list {
            flex: 1 1 auto;

            h3 {
                font-weight: normal;
                padding-bottom: 8px;
                border-bottom: 1px solid;
                @include border-color-step(20);

                a {
                    @include text-color(2);
                }

            }

            .image {
                float: left;
                margin-right: 15px;
            }
        }

        .sidebar {
            flex: 0 0 auto;
            width: 350px;
            margin-left: 30px;

            .sidebar-box {
                padding-bottom: 25px;
            }
        }

        .twitter iframe:last-child {
            display: none !important;
        }

        .xlight & {
            .twitter iframe:first-child {
                display: none !important;
            }

            .twitter iframe:last-child {
                display: block !important;
            }
        }

    }

    @media (max-width: 992px) {
        #page-home {
            .sidebar {
                display: none;
            }
        }
    }

</style>